.Install {
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: fixed;

	.logo {
		width: 50vmin;
		margin: 20px;
	}
}