.Archer {
	.card {
		width: 100px;
		margin-left: calc((100vw - 300px) / 6);
		margin-right: calc((100vw - 300px) / 6);
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.scores-title {
		float: left;
	}

	.scores-result {
		float: right;
	}

	.bad {
		color: #D60602;

		@media (prefers-color-scheme: dark) {
			color: #FF0602;
		}

		&:before {
			content: "↓";
			position: relative;
			bottom: 1.5px;
			left: 0.5px;
		}
	}

	.good {
		color: #09AD00;

		&:before {
			content: "↑";
			position: relative;
			bottom: 1.5px;
			right: 0.5px;
		}
	}
}