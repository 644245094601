.Detail {
	width: 100vw;
	margin-top: 20px;
	margin-bottom: 20px;

	.detail-name {
		float: left;
		margin-left: calc((100vw - 300px) / 6);
	}

	.detail-score {
		float: right;
		margin-right: calc((100vw - 300px) / 6);
	}
}