.Main {
	.content-container {
		position: fixed;
		height: calc(100% - 64px);
		width: 100vw;
		bottom: env(safe-area-inset-bottom, 0px);

		.content {
			text-align: center;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			width: 100vw;
		}
	}
}