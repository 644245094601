html,
body {
	margin: 0;
	padding: 0;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: white;

	@media (prefers-color-scheme: dark) {
		background-color: black;
		color: white;
	}
}

.clear {
	clear: both;
}